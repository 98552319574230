<template>
  <div class="container pd100">
    <div class="StudentScheduleDetail" style="margin-bottom:20px" v-if="!searchLoading">
      <!-- <van-cell title="课程信息" /> -->
      <div class="card">
        <van-image class="card-media" :src="detail.course_cover" />
        <div class="card-inner">
          <div class="card-title ui-ellipsis">{{ detail.course_title }}</div>
          <!-- <div class="card-txt">{{ detail.course_cost }} 课时 / {{ detail.course_time }} 分钟</div> -->
          <div class="card-txt"> {{ detail.course_time }} 分钟</div>
        </div>
      </div>

      <van-cell title="校区名称" :value="course_studio.studio_name" />
      <van-cell title="校区地址" :value="course_studio.studio_addr" is-link @click="onLocation" />
      <van-cell title="上课班级" v-if="detail.class_name" :value="detail.class_name" />
      <van-cell title="上课教室" :value="detail.room_name" />
      <van-cell title="上课时间" :value="detail.class_date" />
      <van-cell title="上课老师" @click="toCellphone"  v-if="course_teacher.teacher_name" :value="course_teacher.teacher_name" :url="`tel://${course_teacher.teacher_cellphone}`">
        <template #right-icon>
          <van-icon name="phone-circle-o" size="23" color="#969799"/>
        </template>
      </van-cell>
      <van-cell title="上课学员" v-if="course_student.student_name" :value="course_student.student_name" />
      <van-cell title="上课资料" v-if="detail.course_knowledge" @click="toKnowledge" :label="detail.course_knowledge.knowledge_name"  is-link :url="detail.course_knowledge.knowledge_link" />
      <van-cell title="扣课课时" v-if="detail.book_costs > 0" :value="`- ${detail.book_costs}`" />
      <van-cell title="教室实况" is-link @click="toLive" v-if="detail.course_rmtp" />
      <van-cell class="mb10" title="课程评价" :value="detail.comment_count" is-link @click="toPath" />
      
      <!-- <div class="teacher">
        <van-cell title="上课老师" />
        <div class="teacher-inner">
          <div class="teacher-hd">
            <van-image class="teacher-avatar" :src="course_teacher.teacher_avatar" />
            <span class="teacher-title">{{ course_teacher.teacher_name }}</span>
          </div>
          <div class="teacher-bd" @click="onNavigator({path:'/teacher/detail?teacher_id='+ course_teacher.teacher_id})"><span>查看详情</span><van-icon name="arrow" /></div>
        </div>
      </div> -->
      <div v-if="detail.class_summary" class="schedule-detail-html">
        <van-cell title="上课内容" />
        <div @click="toPreview($event)" class="ql-editor" v-html="html"></div>
      </div>
      
      <div v-if="detail.book_status === 0">
        <van-cell title="请假备注" />
        <div class="schedule-detail-remark">
          <div>{{detail.cancel_remark}}</div>
          <div class="schedule-detail-remark-img">
            <img v-for="(item,index) in detail.cancel_image" @click="previewRemarkImg(index)" :key="index" :src="item">
          </div>
        </div>
      </div>

      <div class="affix-bar">
        <van-button v-if="detail.book_can_cancel && detail.book_status === 0" type="primary" :disabled='true' block>已请假</van-button>
        <van-button v-if="detail.book_status == 2" type="primary" :disabled='true' block>已上课</van-button>
        <van-button v-if="detail.book_can_cancel && detail.book_status == 1 && appid=='mMKcL4'" type="info" block @click="toReplace">我要调课</van-button>
        <van-button v-if="detail.book_can_cancel && detail.book_status == 1" type="primary" block @click="onSubmit">我要请假</van-button>
        <van-button v-if="detail.book_can_cancel && detail.book_status == 4" type="warning" block @click="onCancelWait">等位序号 {{detail.book_wait_no}} 取消等位</van-button>
      </div>
    </div>
    <loadings :loading='searchLoading' />
    <copyright v-show='!searchLoading' />
    <!-- <Tabbar/> -->
  </div>
</template>

<script>

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import PageMixin from '@/mixins/page'
import Loadings from '@/components/loading'
import Copyright from '@/components/Copyright'
import { ImagePreview ,Dialog} from 'vant'
import { openLocation } from '@/common/wx'
import Tabbar from '@/components/Tabbar'
import {addRandomAndGetPreviewImageUrlsFromRichText} from '@/utils/imgPreview'

export default {
  name: 'StudentScheduleDetail',
  mixins: [PageMixin],
  components: {
    [Dialog.name]:Dialog,
    Copyright,
    Tabbar:Tabbar,
    loadings:Loadings,
  },
  data() {
    return {
      detail: {},
      live_show: false,
      course_studio: {},
      course_teacher: {},
      course_student: {},
      searchLoading:false,
      html:'',
      richImages:[],
      appid:this.$route.query.appid

    }
  },
  created() {
    this.onDetail()
    let appid=localStorage.getItem('appid');
    if(appid=='c8bEt1' || appid=='RGid7'){
      this.live_show=true;
    }
    document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
        WeixinJSBridge.invoke('hideOptionMenu')
    });
  },
  methods: {
    onDetail() {
      this.searchLoading = true
      let book_id = this.$route.query.book_id
      this.$api.schedule_detail({ book_id:book_id }).then(res => {
        this.detail = res.data
        this.course_studio = res.data.course_studio
        this.course_teacher = res.data.course_teacher
        this.course_student = res.data.course_student
        if(res.data.class_summary){
          let arr = addRandomAndGetPreviewImageUrlsFromRichText(res.data.class_summary)
          this.html = arr[0]
          this.richImages = arr[1]
        }
        this.searchLoading = false
      })
    },
    async toCellphone(){
      await this.$api.click_index({event_id:this.detail.book_id,studio_id:this.course_studio.studio_id,event_type:'course_detail_cellphone'}).then(res=>{})
    },
    toKnowledge(){
      this.$api.click_index({event_id:this.detail.book_id,studio_id:this.course_studio.studio_id,event_type:'course_knowledge_detail'}).then(res=>{})
    },
    onSubmit() {
      this.$router.push({ path: '/student/ask-leave?book_id=' + this.detail.book_id })
    },
    onCancelWait(){
       Dialog.confirm({
          title: '等位取消确认',
          message: '当前预约为等位预约，是否取消等位。',
      })
      .then(() => {
          this.loading=true;
          this.$api.schedule_cancel_wait({ book_id: this.$route.query.book_id }).then(res => {
            this.loading = false
            this.$toast.clear()
            this.$toast({ message: '取消成功.', duration: 1500 })
            localStorage.setItem('schedule_refresh', true)
            this.$router.push({path:'/schedule'})
          })
          this.$api.click_index({event_id:0,studio_id:0,event_type:'appointment_waitlist_cancel_btn'}).then(res=>{})
          
      })
      .catch(() => {
          // on cancel
      });
    },
    toLive(){
      this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'course_class_live'}).then(res=>{})
      this.$router.push({path:'/student/schedule/schedule-live',query:{book_id:this.$route.query.book_id}})
    },
    toPath(){
      this.$router.push({path:'/student/schedule/schedule-comment',query:{book_id:this.$route.query.book_id}})
    },
    toReplace(){
      this.$router.push({path:'/huidong/appointment',query:{book_id:this.$route.query.book_id}})
    },
    toPreview(e){
        if (e.target.tagName == 'IMG') {
            this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'course_class_summary'}).then(res=>{})
            WeixinJSBridge.invoke("imagePreview", {
                "urls":this.richImages,
                "current": this.richImages[e.target.src.split('random=')[1] - 1]
            });
            // ImagePreview({
            //     images: this.richImages,
            //     startPosition: e.target.src.split('random=')[1] - 1,
            //     closeOnPopstate: true //页面回退关闭预览
            // })
        }
    },
    previewRemarkImg(index){
        WeixinJSBridge.invoke("imagePreview", {
            "urls":this.detail.cancel_image,
            "current": this.detail.cancel_image[index]
        });
    },
    onLocation() {
      openLocation({
        longitude: this.detail.course_studio.studio_lng,
        latitude: this.detail.course_studio.studio_lat,
        name: this.detail.course_studio.studio_name,
        address: this.detail.course_studio.studio_addr
      })
    },
  }
}
</script>
<style lang="less" scoped>
  .card{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    &-media{
      width: 130px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 10px;
    }
    &-inner{
      flex: 1;
      height: 60px;
    }
    &-title{
      max-width: 140px;
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }
    &-txt{
      font-size: 12px;
      color: #666;
    }
  }
  .teacher{
    &-inner{
      padding: 10px 15px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
    }
    &-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }
    &-title{
      font-size: 14px;
      color: #333;
    }
    &-hd{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-bd{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      color: #666;
    }
  }
</style>

<style lang="less">
    .schedule-detail-html{
      margin-top: 10px;
      img{
        max-width: 100%;
      }
    }
    .StudentScheduleDetail{
      .van-cell__value{
        flex: 3.5;
      }
    }
    .schedule-detail-remark{
      background: #fff;
      padding: 10px 15px 15px 15px;
      &-img{
        img{
          height: 100px;
          margin-right: 10px;
          margin-top: 15px;
        }
      }
    }
</style>

