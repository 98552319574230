<template>
  <van-tabbar route>
      <van-tabbar-item v-if="tabbarArr.home" :dot="tabbars.home" :to="tabbarArr.home" icon="home-o">首页</van-tabbar-item>
      <!-- <van-tabbar-item v-if="tabbarArr.recruit" icon="" :to="tabbarArr.recruit" >报班</van-tabbar-item> -->
      <van-tabbar-item v-if="tabbarArr.recruit" :dot="tabbars.recruit" :to="tabbarArr.recruit" icon="balance-list-o">报班</van-tabbar-item>
      <van-tabbar-item v-if="tabbarArr.schedule" :dot="tabbars.schedule" :to="tabbarArr.schedule" icon="todo-list-o">课表</van-tabbar-item>
      <van-tabbar-item v-if="tabbarArr.mall" :dot="tabbars.mall" :to="tabbarArr.mall" icon="shop-o">商城</van-tabbar-item>
      <van-tabbar-item v-if="tabbarArr.message" :dot="tabbars.message" :to="tabbarArr.message" icon="chat-o">档案</van-tabbar-item>
      <van-tabbar-item v-if="tabbarArr.profile" :dot="tabbars.profile" :to="tabbarArr.profile" icon="user-circle-o">我的</van-tabbar-item>
    </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem} from 'vant'
export default {
  name: 'Tabbar',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    tabbars() {
      return this.$store.state.app.tabbars
    },
    tabbarArr() {
      // console.log(this.$store.getters.globalData.brand.tabbar);
      let bar = this.$store.getters.globalData.brand.tabbar;
      let num = localStorage.getItem('profile_employee')
      if(num == 1){
        bar.profile='/profile'
      }else{
        bar.profile='/user'
      }
      return bar
      console.log(bar)
      return this.$store.getters.globalData.brand.tabbar
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>

</style>

